import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../editor-app/manifest/getRole';

export enum CtaButtonsWidgetComponentIds {
  TopContainer = '#box2',
  multiState = '#portfolioMultiStateBox',
  categoriesGalleryState = '#categoriesGalleryState',
  categoriesGalleryWidget = '#categoriesGalleryWidget',
  categoriesListState = '#categoriesListState',
  categoriesListWidget = '#categoriesListWidget',
  categoryState = '#categoryState',
  categoryWidget = '#categoryWidget',
}

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
) => {
  controllerBuilder.set({
    displayName: 'Portfolio Page Widget',
    nickname: 'portfolioPageWidget',
  });

  controllerBuilder
    .gfpp()
    .set('mainAction1', {
      label: 'Manage Projects',
      onClick: () => {
        void editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/projects',
          closeOtherPanels: false,
        });
      },
    })
    .set('mainAction2', {
      label: 'Add New Project',
      onClick: () => {
        void editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/categories',
          closeOtherPanels: false,
        });
      },
    })
    .set('layout', { actionId: 'openPortfolioLayoutPanel' });

  // define widget design panel
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: 'Portfolio Design',
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Box',
          })
          .groups()
          .set({
            roles: [CtaButtonsWidgetComponentIds.TopContainer].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'multiState',
          })
          .groups()
          .set({
            roles: [CtaButtonsWidgetComponentIds.multiState].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'categoriesGalleryState',
          })
          .groups()
          .set({
            roles: [CtaButtonsWidgetComponentIds.categoriesGalleryState].map(
              getRole,
            ),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'categoriesGalleryWidget',
          })
          .groups()
          .set({
            roles: [CtaButtonsWidgetComponentIds.categoriesGalleryWidget].map(
              getRole,
            ),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'categoriesListState',
          })
          .groups()
          .set({
            roles: [CtaButtonsWidgetComponentIds.categoriesListState].map(
              getRole,
            ),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'categoriesListWidget',
          })
          .groups()
          .set({
            roles: [CtaButtonsWidgetComponentIds.categoriesListWidget].map(
              getRole,
            ),
          });
      });
  });
};
