import { EditorSDK, AppManifest } from '@wix/platform-editor-sdk';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { configurePages, configPagesTabs } from './manifest/configurePages';
import { configureManagementActions } from './manifest/configureManagementActions';

export const modifyAppManifest = async (
  options,
  editorSDK: EditorSDK,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  appManifestBuilder
    .withJsonManifest(baseManifest)
    .configurePages(configurePages)
    .configurePagesTab(configPagesTabs);
  // .configureManagementActions(configureManagementActions);
  return appManifestBuilder.build();
};
