import type {
  EditorSDK,
  PageRef,
  ComponentRef,
} from '@wix/platform-editor-sdk';
import { createWidgetDefinition } from './createWidgetDefinition';
import { createSectionDefinition } from './createSectionDefinition';
import { InstallAppData, PresetWidget } from '../types/types';

const classicDefaultSize = {
  width: 980,
  height: 500,
};

export const createAppPage = async ({
  editorSDK,
  isResponsive,
  appDefId,
  pageData,
  shouldAddMenuItem = false,
  shouldNavigateToPage = false,
}: InstallAppData): Promise<PageRef> => {
  const ppPageRef = await addWidgetAsPage({
    editorSDK,
    isResponsive,
    appDefId,
    pageData,
    shouldAddMenuItem,
    shouldNavigateToPage,
  });
  const { pageId } = pageData;
  if (!isResponsive) {
    await addWidgetWithPresets({ editorSDK, ppPageRef, pageData });
  }
  editorSDK.document.pages.setState('token', {
    state: { [pageId]: [ppPageRef] },
  });
  return ppPageRef;
};

const addWidgetAsPage = ({
  editorSDK,
  isResponsive,
  appDefId,
  pageData,
  shouldAddMenuItem = false,
  shouldNavigateToPage = false,
}: InstallAppData): Promise<PageRef> => {
  const { pageId, widgetId, presetId, pageUriSEO } = pageData;
  return editorSDK.pages.add('', {
    title: pageId,
    definition: {
      id: '',
      type: 'Page',
      components: isResponsive
        ? [
            createSectionDefinition([
              createWidgetDefinition({
                appDefinitionId: appDefId,
                widgetId,
                presetId,
              }),
            ]) as any,
          ]
        : [],
      data: {
        managingAppDefId: appDefId,
        tpaPageId: pageId,
        pageUriSEO,
      },
      componentType: 'mobile.core.components.Page',
    },
    shouldAddMenuItem,
    shouldNavigateToPage,
  });
};

const addWidgetWithPresets = ({
  editorSDK,
  ppPageRef,
  pageData,
}: PresetWidget): Promise<ComponentRef> => {
  const { widgetId, presetId } = pageData;
  return editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId,
    layout: {
      docked: {
        left: {
          px: 0,
          vw: 0,
        },
        right: {
          px: 0,
          vw: 0,
        },
      },
      x: 0,
      y: 0,
      fixedPosition: false,
      width: classicDefaultSize.width,
      height: classicDefaultSize.height,
      scale: 1,
      rotationInDegrees: 0,
    } as any,
    scopedPresets: {
      desktop: { layout: presetId, style: presetId },
      mobile: { layout: presetId, style: presetId },
    },
    installationType: 'closed',
    containerRef: ppPageRef as ComponentRef,
  });
};
