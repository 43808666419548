import type { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';
import { Pages } from './const/pagesConsts';
import { createAppPage } from './utils/installationUtils';

export async function handleFirstInstall(
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
): Promise<void> {
  const isResponsive = options.origin.type === 'RESPONSIVE';
  const portfolioPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.PORTFOLIO_PAGE,
    isResponsive,
    shouldAddMenuItem: true,
    shouldNavigateToPage: true,
  });
  const categoryPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.CATEGORY_PAGE,
    isResponsive,
  });
  const projectPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.PROJECT_PAGE,
    isResponsive,
  });
  const tagsPageRef = await createAppPage({
    editorSDK,
    appDefId,
    pageData: Pages.TAG_PAGE,
    isResponsive,
  });
}
